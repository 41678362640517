


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.clip-image-right{
  clip-path: polygon(0 0,100% 0,85% 100%,0 100%);
}

@media (max-width: 991px) {
  .clip-image-top {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (max-width: 991px) {
  .clip-image-top {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
}

@media (max-width: 991px) {
  .clip-image-top {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
}

.info-bg{
  background-image: url("../public/assets/space2.svg");
}

.custom-flex {
  display: flex !important;
  align-items: center !important;
  flex-direction: column;
}

@media (min-width: 991px) {
  .custom-flex {
      display: flex !important;
      align-items: center !important;
      flex-direction: row;
      justify-content: space-around;
  }
}